import styles from '@/src/components/StatusScreen/StatusScreen.module.scss'
import cn from 'classnames'
import React, { ReactNode } from 'react'
import { BsCheckLg, BsFillCloudRainFill } from 'react-icons/bs'

type StatusScreenProps = {
  status?: 'success' | 'default'
  children: React.ReactNode
  noPadding?: boolean
  icon?: React.ReactNode
  showIcon?: boolean
  classes?: string
  layer?: boolean
}

const StatusScreen = ({
  children,
  status = 'default',
  layer = true,
  noPadding,
  icon,
  showIcon = true,
  classes = '',
}: StatusScreenProps) => {
  let iconNode: ReactNode
  if (icon) {
    iconNode = icon
  } else {
    if (status === 'success') {
      iconNode = <BsCheckLg size='32px' />
    } else {
      iconNode = <BsFillCloudRainFill size='32px' />
    }
  }

  return (
    <div
      className={cn(
        styles[status],
        styles.root,
        layer ? `fixed left-0 top-0 z-10 justify-center` : `mt-[50%]`,
        `flex h-screen w-full flex-col items-center`,
        classes,
      )}
    >
      {showIcon && (
        <div
          className={cn(
            styles[status],
            styles.icon,
            'z-10 mb-5 translate-x--2/4 translate-y--2/4',
          )}
        >
          {iconNode}
        </div>
      )}
      <div
        className={cn(
          'translate-x--3/4 translate-y--3/4',
          {
            'px-8': !noPadding,
          },
          'z-10 flex w-full max-w-xl flex-col items-center justify-center pb-2 text-center',
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default StatusScreen

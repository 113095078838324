'use client'

import React, { ReactElement } from 'react'

import ShowFinishOnbaording from '@/src/components/ShowFinishOnbaording'
import ShowLoadingModal from '@/src/components/ShowLoadingModal'
import useAuthGuard from '@/src/hooks/useAuthGuard'
import cn from 'classnames'
import dynamic from 'next/dynamic'
const DynamicTopBar = dynamic(() => import('../TopBar/TopBar'), { ssr: false })

export type PageProps = {
  children?: React.ReactNode
  fullWidth?: boolean
  title?: string | ReactElement
  noPadding?: boolean
  extraPadding?: boolean
  inGame?: boolean
  className?: string
  hideNavbar?: boolean
  token?: string | null
  disableHeaderActions?: boolean
  secondaryHeaderAction?: ReactElement
  disableSGABar?: boolean
  moving?: boolean
  blurred?: boolean
  ssrFooter?: React.ReactNode
  searchReplaceUrl?: boolean
}

const Page = ({
  children,
  disableHeaderActions,
  fullWidth,
  noPadding = false,
  extraPadding = false,
  inGame = false,
  className,
  secondaryHeaderAction,
  disableSGABar = false,
  title,
  moving = true,
  blurred = false,
  ssrFooter,
  searchReplaceUrl = false,
}: PageProps) => {
  const { isLoggedIn } = useAuthGuard()
  return (
    <div className={'inset-x-0 z-10 bg-primary'}>
      <DynamicTopBar
        moving={moving}
        inGame={inGame}
        isLoggedIn={isLoggedIn}
        secondaryHeaderAction={secondaryHeaderAction}
        disableSGABar={disableSGABar}
        disableHeaderActions={disableHeaderActions}
        blurred={blurred}
        searchReplaceUrl={searchReplaceUrl}
      />

      <ShowLoadingModal />

      <ShowFinishOnbaording />

      <div
        className={cn('mx-auto min-h-screen', className, {
          'pt-36': !noPadding && moving && !extraPadding,
          'pt-36 md:pt-48': extraPadding,
          'pt-2 md:pt-16': disableHeaderActions,
          'max-w-7xl': !fullWidth,
          'px-3': !fullWidth,
        })}
      >
        {title && title}
        {children}
      </div>
      {ssrFooter}
    </div>
  )
}

export default Page

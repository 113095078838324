'use client' // Error components must be Client Components
import { RoundedButton } from '@/src/components/Button'
import Page from '@/src/components/Layout/Page'
import LocalizedLink from '@/src/components/LocalizedLink'
import StatusScreen from '@/src/components/StatusScreen'
import Heading from '@/src/components/Typography/Heading'
import IntercomWidget from '@/src/widgets/IntercomWidget.tsx'
import { captureException } from '@sentry/nextjs'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type ErrorType = {
  error: Error & { digest?: string }
  reset: () => void
}

export default function Error({ error, reset }: ErrorType) {
  const { t } = useTranslation(['server-errors', 'common'])

  useEffect(() => {
    captureException(error)
  }, [error])

  return (
    <Page>
      <div className='pointer-events-none absolute left-0 top-0 h-screen w-screen overflow-x-hidden' />
      <StatusScreen>
        <>
          <Heading level={1}>{t('err.something.went.wrong')}</Heading>

          <p className='mb-8 px-8 text-center opacity-80'>
            {t('err.contact.support')}
          </p>

          <LocalizedLink href='/'>
            <RoundedButton type='secondary' className='mb-3'>
              {t('common:go-back')}
            </RoundedButton>
          </LocalizedLink>

          {reset && (
            <RoundedButton onClick={reset} type='primary' className='mb-3'>
              {t('common:retry')}
            </RoundedButton>
          )}

          <IntercomWidget className='opacity-60'>
            {t('common:contact-support')}
          </IntercomWidget>
        </>
      </StatusScreen>
    </Page>
  )
}
